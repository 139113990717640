<template>
    <div class="order-down">
        <el-dialog
            title="批量发送指令"
            :visible.sync="show"
            :close-on-click-modal="false"
            width="816px">
            <el-form :model="orderInfo" :rules="rules" ref="deviceInfo" label-width="68px" class="data-form">
                <el-form-item label="指令类型" prop="orderType">
                    <el-select class="input-w290-h32 order-sec" v-model="orderInfo.orderType" placeholder="请选择">
                        <el-option
                        v-for="item in modelOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 指令循环 -->
                <div>
                    <el-form label-width="120px">
                        <el-form-item :label="item.paramName" v-for="(item,index) in formList" :key="index">
                            <el-input v-if="item.paramType == 'input'" class="input-w290-h32" v-model="item.defaultValue" size="small">
                            </el-input>
                            <el-input v-else-if="item.paramType == 'text'" class="input-w290-h32" v-model="item.defaultValue"
                            size="small"></el-input>
                            <el-input v-else-if="item.paramType == 'tel'" class="input-w290-h32" v-model="item.defaultValue" size="small">
                            </el-input>
                            <el-input v-else-if="item.paramType == 'nub'" class="input-w290-h32" v-model="item.defaultValue" size="small">
                            </el-input>
                            <el-input v-else-if="item.paramType == 'email'" class="input-w290-h32" v-model="item.defaultValue"
                            size="small"></el-input>
                            <el-input v-else-if="item.paramType == 'password'" class="input-w290-h32" v-model="item.defaultValue"
                            size="small"></el-input>
                            <el-select v-else-if="item.paramType == 'select'" class="input-w290-h32" size="small" v-model="item.defaultValue">
                            <el-option v-for="(key,index) in JSON.parse(item.paramValue)" :key="index" :label="key.name"
                                :value="key.val"></el-option>
                            </el-select>
                            <el-radio-group v-else-if="item.paramType == 'radio'" class="input-w290-h32" size="small" v-model="item.defaultValue">
                            <el-radio v-for="(key,index) in JSON.parse(item.paramValue)" :key="index" :label="key.name"
                                :value="key.val"></el-radio>
                            </el-radio-group>
                            <el-checkbox-group v-else-if="item.paramType == 'checkbox'" class="input-w290-h32" size="small" v-model="item.defaultValue">
                            <el-checkbox v-for="(key,index) in JSON.parse(item.paramValue)" :key="index" :label="key.name"
                                :value="key.val" name="type"></el-checkbox>
                            </el-checkbox-group>
                            <el-input v-else-if="item.paramType == 'textarea'" type="textarea" size="small" v-model="item.paramValue">
                            </el-input>
                            <el-input v-else v-model="item.paramValue" class="input-w290-h32" size="small"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <el-form-item label="已选设备" class="el-w292"></el-form-item>
                <div class="table-list order-table">
                    <Table :table="table" :showPage="showPage"></Table>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <tem-btn  type="info" @click="show = false"  value="取消"></tem-btn>
                <tem-btn  type="primary" @click="submitData"  value="发送指令"></tem-btn>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import popover  from '@/components/popover/index'
import Table from '@/components/table/table';
import { listDeviceModelByOrgId } from '@/api/deviceManagement';
import { listTreeOrg } from '@/api/organization-management/index';
export default {
    model: {
        prop: 'deviceDialogVisible',
        event: 'deviceEvent'
    },
    components:{
        Table,
        popover
    },
    props:{
        deviceDialogVisible:{
            type: Boolean,
            default: true
        },
        orderArr:{
            type: Array,
            default:() => {
                return []
            }
        },
    },
    data(){
        return {
            show: this.deviceDialogVisible,
            rules:{
                orderType: [{ required: true, message: '请选择指令类型', trigger: 'change' }],
                orgName:[{ required: true, message: '请选择机构', trigger: 'change' }],
            },
            modelOptions: [{value: 1, label: '自定义指令'}],
            newTreeData:[],
            openOrClose: false,
            showPage:false, // 是否显示分页
            orderInfo: {}, // 绑定值
            formList: [], // 指令list
            //表格数据
            table: {
                configItem: false, //是否开启表格项配置
                data: [],
                maxHeight: 344,
                header: [
                    {
                    prop: 'imei',
                    label: 'IMEI',
                    // minWidth: '180',
                    ifShow: true,
                    },
                    {
                    prop: 'deviceModel',
                    label: '设备型号',
                    // minWidth: '120',
                    tooltip: true,
                    ifShow: true,
                    },
                    {
                    prop: 'updateTime',
                    label: '关联车辆',
                    // minWidth: '180',
                    tooltip: true,
                    ifShow: true,
                    },
                    {
                    prop: 'options',
                    label: '操作',
                    // minWidth: '180',
                    ifShow: true,
                    align: 'center',
                    fixed: 'right',
                    render:(h,param,index) => {
                        return h('div', [
                            h(
                                'el-tooltip', {
                                    props: {
                                        effect:'dark',
                                        content:'删除',
                                        placement:'top',
                                    }
                                },
                                [h('div',{
                                    style:{
                                        cursor:'pointer',
                                        display: 'inline-block',
                                        outline: 'none'
                                    },
                                    on:{
                                        click:()=> {
                                            this.orderArr.splice(0,1);
                                        },
                                    }
                                },[
                                    h('svg-icon',{
                                        props:{
                                            iconClass:'icon-del',
                                            size: '16',
                                            className:'icon'
                                        }
                                    })
                                ])
                                ]
                            ),
                        ]);
                    }
                    }
                ],
            }
        }
    },
    mounted(){
        // this.listDeviceModelByOrgId();
    },
    watch: {
        //对父组件传来的地址进行监听
        show:{
            handler(newVal, oldVal) {
                this.$emit('deviceEvent',newVal);
            },
            deep: true,
        },
        deviceDialogVisible:{
            handler(newVal, oldVal) {
                this.show = newVal;
            },
            deep: true,
        },
        orderArr(newVal, oldVal) {
            this.table.data = this.orderArr;
        }
    },
    methods:{
        submitData(){
            this.$emit('submitData',this.orderInfo);
        },
        // nodeClick(val, flag){
        //     // console.log(val);
        //     this.deviceInfo.orgId = val.orgId;
        //     this.deviceInfo.orgName = val.orgName;
        //     if (flag == 'db') {
        //         this.openOrClose = false;
        //     }
        // },
        listDeviceModelByOrgId(){
            listDeviceModelByOrgId().then( res => {
                this.modelOptions = res.data;
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.order-down {
    .order-table {
        padding: 0 0 20px 0;
    }
    .el-w292 {
        margin-bottom: 8px;
    }
}
</style>
<style lang="scss">
.order-down {
    .order-sec {
        width: 292px;
    }
}
</style>
