<template>
    <div class="import-batch">
        <el-dialog
            title="批量导入"
            :visible.sync="show"
            :close-on-click-modal="false"
            width="568px">
            <div class="import-div">
                <div class="down-div" @click="downTemplate">
                    <div>
                        <svg-icon class="svg-icon icon-link" icon-class="icon_common_download" size="16"></svg-icon>
                        <span>下载模版</span>
                    </div>
                    <div>将要导入的设备信息填入模版中</div>
                </div>

                <el-upload
                class="upload-demo"
                action="22222"
                ref="upload"
                accept=".xls,.xlsx"
                :auto-upload="false"
                :on-preview="handlePreview"
                :on-change="uploadFile"
                :before-remove="fileRemove"
                :file-list="fileList">
                    <div class="up-div">
                        <div>
                            <svg-icon class="svg-icon icon-link" icon-class="icon_common_upload" size="16"></svg-icon>
                            <span>本地上传</span>
                        </div>
                        <div>上传格式Excel</div>
                    </div>
                </el-upload>
            </div>

            <div slot="footer" class="dialog-footer" v-if="showDiv">
                <tem-btn  type="info" @click="show = false"  value="取消"></tem-btn>
                <tem-btn  type="primary" @click="submitData"  value="确定"></tem-btn>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { deviceDownloadTemplate } from '@/api/deviceManagement';
import { getOrgId } from '@/utils/cookie'
export default {
    model: {
        prop: 'impotDialogVisible',
        event: 'organizationEvent'
    },
    props:{
        impotDialogVisible:{
            type: Boolean,
            default: true
        },
    },
    data(){
        return {
            show: this.impotDialogVisible,
            fileList: [],
            listQuery: {
                deviceFile: null
            },
            showDiv: false, // 是否显示上传下载框
        }
    },
    watch: {
        //对父组件传来的地址进行监听
        show:{
            handler(newVal, oldVal) {
                this.$emit('organizationEvent',newVal);
            },
            deep: true,
        },
        impotDialogVisible:{
            handler(newVal, oldVal) {
                this.show = newVal;
            },
            deep: true,
        },
    },
    methods:{
        /** 下载模板 */
        downTemplate() {
            deviceDownloadTemplate().then(res => {
                let result = res.data;
                let url =  window.URL.createObjectURL(new Blob([result]));//处理文档流
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;

                link.download = 'device.xlsx';
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(link.href) // 释放URL 对象
                document.body.removeChild(link) // 释放标签
            })
        },
        /** 点击上传成功的文件 */
        handlePreview(file) {
            // console.log(file);
        },
        /** 文件改变 */
        uploadFile(file, fileList) {
            // console.log(file,this.fileList);
            this.showDiv = true;
            this.fileList = fileList.slice(-1);
            this.listQuery.deviceFile = file.raw;
        },
        /** 上传移除 */
        fileRemove() {
            this.showDiv = false;
        },
        submitData(){
            // console.log(this.listQuery,666);
            this.show = false;
            let data = new FormData();
            data.append("file", this.listQuery.deviceFile);
            data.append("orgId", getOrgId());
            this.$emit('submitFile',data);
        },
    }
}
</script>

<style lang="scss" scoped>
    .import-div {
        display: flex;
        justify-content: space-between;
        position: relative;
        .down-div,
        .up-div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 240px;
            height: 120px;
            border-radius: 4px;
            border: 1px dashed rgba(50, 112, 255, 0.5);
            background: rgba(50, 112, 255, 0.05);
            text-align: center;
            font-size: 12px;
            color: #595959;
            cursor: pointer;
            span {
                font-size: 14px;
                font-weight: 500;
                color: #3270FF;
            }
        }
        .up-div {
            border: 1px dashed rgba(255, 138, 27, 0.5);
            background: rgba(255, 138, 27, 0.05);
            span {
                color: #FF8618;
            }
        }
        .down-div:hover {
            border: 1px solid rgba(50, 112, 255, 0.5);
            background: rgba(50, 112, 255, 0.1);
        }
        .up-div:hover {
            border: 1px solid rgba(255, 138, 27, 0.5);
            background: rgba(255, 138, 27, 0.1);
        }
    }
</style>

<style lang="scss">
    .import-batch {
        .el-dialog__body {
            padding: 32px 32px 32px 32px;
        }
        .el-upload-list {
            position: absolute;
            top: 144px;
            left: 0;
            width: calc(100%);
        }
        .dialog-footer {
            padding-top: 68px;
        }
    }
</style>