<template>
  <div>
    <div class="organization-management">
        <div class="search-nav">
            <div class="search-form">
                <el-form :inline="true" ref="listQuery" :model="listQuery">
                    <div class="form">
                        <div class="search-conditions">
                            <el-form-item label="车牌号码" prop="licensePlate">
                                <el-input class="input-w290-h32" v-model="listQuery.licensePlate" placeholder="请输入车牌号"></el-input>
                            </el-form-item>
                            <el-form-item label="设备IMEI" prop="imeiLike">
                                <el-input class="input-w290-h32" v-model="listQuery.imeiLike" placeholder="请输入IMEI"></el-input>
                            </el-form-item>
                            <el-form-item label="SIM卡号" prop="sim">
                                <el-input class="input-w290-h32" v-model="listQuery.sim" placeholder="请输入SIM卡号"></el-input>
                            </el-form-item>
                            <el-form-item label="设备状态" prop="isOnline">
                                <el-select class="input-w290-h32" v-model="listQuery.isOnline" placeholder="请选择设备状态" clearable>
                                    <el-option
                                    v-for="item in options"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="search-btns">
                            <el-form-item class="clear-margin-bottom clear-margin-right" label="" prop="includeSub">
                                <el-checkbox v-model="listQuery.includeSub">包含下级</el-checkbox>
                            </el-form-item>
                            <tem-btn  type="primary" value="查询" @click="getTableList('search')"></tem-btn>
                            <tem-btn  type="info" value="清空" @click="restForm('listQuery')"></tem-btn>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="table-list equiment-table">
            <Table :table="table" :total="listQuery.total" :page.sync="listQuery.pageIndex" :size.sync="listQuery.pageSize"
            :orderBy.sync="listQuery.orderBy" @pagination="getTableList" @changeSelect="changeSelect"></Table>
        </div>
    </div>
    
    <order-down @submitData="submitData" v-model="deviceDialogVisible" :orderArr="JSON.parse(JSON.stringify(orderArr))"></order-down>
    <batch-impot @submitFile="submitFile" v-model="impotDialogVisible"></batch-impot>

    <el-dialog
    title="解绑设备"
    :visible.sync="unbindShow"
    :close-on-click-modal="false"
    width="434px">
        <el-form :model="unbindObj" label-width="68px" class="data-form">
            <el-form-item label="IMEI" class="el-w292">
                <el-input class="input-w290-h32" v-model="unbindObj.imei" placeholder="请输入设备IMEI确认"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <tem-btn  type="info" @click="unbindShow = false"  value="取消"></tem-btn>
            <tem-btn  type="primary" @click="unbindClick"  value="确定"></tem-btn>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import Table from '@/components/table/table';
import popover  from '@/components/popover/index';
import orderDown  from '@/views/equipment-management/orderDown';
import batchImpot  from '@/views/equipment-management/batchImpot';
import { deviceList, batchImportDevice, deviceDownloadTemplate, editDevicePort, deleteDevice } from '@/api/deviceManagement';
import { listTreeOrg } from '@/api/organization-management/index';
  export default {
    components: {
      Table,
      popover,
      orderDown,
      batchImpot
    },
    data() {
      return {
        //搜索栏数据
        listQuery:{
            licensePlate:'',//车牌号
            imeiLike:'',//设备IMEI
            isOnline:'',//状态
            total:10000,//数据条数
            includeSub:false,//是否包含下级
            pageIndex: 1,//当前查询页面
            pageSize: 10,//每页数据数量
            orderBy:'',
            sim: ''
        },
        options: [{
            value: '1',
            label: '在线'
        }, {
            value: '2',
            label: '离线'
        }],
        deviceInfo: {}, // 设备数据
        deviceDialogVisible: false, // 编辑弹窗
        impotDialogVisible: false, // 导入弹窗
        title: '', // 弹框标题
        newTreeData:[],
        openOrClose: false,
        isReset: true, // 是否重置组织树
        orderArr: [], // 下发指令数组
        selectArr: [], // 多选表格数据
        unbindShow: false, // 解绑弹窗
        unbindObj: {
            imei:''
        }, // 解绑数据
        //表格数据
        table: {
          configItem: true, //是否开启表格项配置
          data: [],
          //表头按钮
          operateBtns:[
              {
                  value:'批量导入',
                  type:'primary',
                  event:() => {
                      this.impotDialogVisible = true;
                  }
              },
              {
                  value:'批量下发指令',
                  type:'info',
                  event:() => {
                    this.orderArr = this.selectArr;
                    if (!this.orderArr.length) {
                        this.$message({
                            message: '请先选择需要下发的设备！',
                            type: 'warning'
                        });
                        return
                    }
                    this.deviceDialogVisible = true;
                  }
              },
              {
                  value:'导出',
                  type:'info',
                  event:() => {
                    //   this.impotDialogVisible = true;
                  }
              },
          ],
          header: [
            {
              type: 'selection',
            },
            {
              type: 'index',
              label: '序号',
              width: '55',
              ifShow: true,
            },
            {
              prop: 'imei',
              label: 'IMEI',
              minWidth: '180',
              ifShow: true,
            },
            {
              prop: 'deviceModel',
              label: '设备型号',
              minWidth: '120',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'carNameStr',
              label: '关联车辆',
            //   sortable: 'column',
              minWidth: '180',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'sim',
              label: 'SIM卡号',
              minWidth: '180',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'createTime',
              label: '设备导入',
              sortable: 'column',
              minWidth: '180',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'activeTime',
              label: '关联时间',
              sortable: 'column',
              minWidth: '180',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'isOnline',
              label: '状态',
              minWidth: '100',
              tooltip: true,
              ifShow: true,
              render:(h,param) => {
                var isOnline = param.isOnline == 1 ? '在线' : '离线'
                var iconClass = param.isOnline == 1 ? 'icon_common_status_on_blue' : 'icon_common_status_off_red'
                return h('div', {
                    style: {
                        display: 'flex',
                        alignItems: 'center',
                        color: param.isOnline == 1 ? '#3270FF' : '#EE3932'
                    }
                }, [
                    h('svg-icon',{
                        props:{
                            iconClass:iconClass,
                            size: '16',
                            className:'icon'
                        },
                    }),
                    h('div',{
                        props:{
                            iconClass:iconClass,
                            size: '16',
                            className:'icon'
                        },
                        style: {
                            marginLeft: '6px'
                        }
                    },isOnline),
                ], );
              }
            },
            {
              prop: 'options',
              label: '操作',
              minWidth: '180',
              ifShow: true,
              align: 'center',
              fixed: 'right',
              render:(h,param) => {
                return h('div', [
                    h(
                        'el-link', {
                            props: {
                                type:'primary',
                            },
                            style: {
                                color: '#3270FF'
                            },
                            on:{
                                click:()=> {
                                    this.deviceDialogVisible = true;
                                    this.orderArr = [param];
                                }
                            }
                        },'下发指令'
                    ),
                    h(
                        'el-link', {
                            props: {
                                type:'primary',
                            },
                            style: {
                                marginLeft: '20px',
                                color: '#3270FF'
                            },
                            on:{
                                click:()=> {
                                    this.unbindObj.imei = '';
                                    this.unbindShow = true;
                                    this.deviceInfo = JSON.parse(JSON.stringify(param));
                                }
                            }
                        },'解绑'
                    ),
                ]);
              }
            }
          ],
        }
      }
    },
    mounted() {
        //获取列表数据
        this.getTableList();
    },
    methods: {
        /**
         * 重置搜索导航栏
         * @param { Object } formName 当前表单的ref值
         */
        restForm(formName){
            this.isReset = false;  // 是否重置组织树避免重置后回显
            this.openOrClose = false;
            this.listQuery.orgId = '';
            this.$refs[formName].resetFields();
            setTimeout(() => {
                this.isReset = true;
            },0)
        },
        /**
         * 获取表格数据
         */
        getTableList(val){
            if(val == "search"){
                this.listQuery.pageIndex =1 ;
            }
            deviceList(this.listQuery).then( res => {
                if( res.code === 200 ) {
                    this.table.data = res.data;
                    this.listQuery.total = res.count;
                }
            })
        },
        /**
         * 多选数据
         * @param {Object } val 选中的数据 
         */
        changeSelect(val) {
            // console.log(val)
            this.selectArr = val;
        },
        /**
         * 表单提交数据
         * @param {Object } orderInfo 用户信息对象 
         */
        submitData(orderInfo){
            let newOrderInfo = JSON.parse(JSON.stringify(orderInfo));
            // if(this.title == 'add') {
            //     // addUserAndRole(deviceInfo).then( res => {
            //     //     if( res.code === 200 ) {
            //     //         this.userDialogVisible = false;
            //     //         this.getTableList();
            //     //     }
            //     // })
            // } else if( this.title == 'edit') {
            //     editDevicePort(newDeviceInfo).then( res => {
            //         if( res.code === 200 ) {
            //             this.deviceDialogVisible = false;
            //             this.getTableList();
            //             this.$message({
            //                 type: 'success',
            //                 message: '编辑成功!'
            //             });
            //         }
            //     })
            // }
        },
        /** 批量上传文件 */
        submitFile(data) {
            batchImportDevice(data).then( res => {
                if( res.code === 200 ) {
                    this.getTableList();
                    this.$message({
                        type: 'success',
                        message: res.message
                    });
                }
            })
        },
        /** 解绑 */
        unbindClick() {
            if (this.unbindObj.imei != this.deviceInfo.imei) {
                this.$message({
                    type: 'warning',
                    message: '输入IMEI与要解绑的IMEI不一致'
                });
            }
        },
    }
  }

</script>
<style lang="scss" scoped>
.organization-management {
    .el-tooltip {
      margin: 0 10px;
    }
}

</style>
<style>
.equiment-table .operate {
    width: 108px;
    height: 32px;
    line-height: 32px;
    text-align: center;
}
</style>